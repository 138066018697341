<template>
  <div class="liff-staff-list">

    <div class="liff-staff-list__content" :class="{ 'need-bottom': !!branchId }">
      <ShopSelection
        ref="shopSelection"
        v-model="branchId"
        v-if="reservationBranchProvider"
        @setBranchModel="setSelectedBranch"
        :branch-provider="reservationBranchProvider"
        :show-branch-code="isShowBranchCode"
        :placeholder="displayPlaceholder"
        @loading="handleBranchesAreLoading"
        @loaded="handleBranchesAreLoaded"
      />
    </div>

    <div class="text-center my-2" v-if="fetching">
      <b-spinner class="align-middle spinner-border"></b-spinner>
    </div>

    <div class="liff-staff-list__confirm">
      <div class="s-flex s-justify-between s-items-center" style="gap: 8px;">
        <button
          class="s-btn s-liff-theme-btn s-btn-sm"
          type="button"
          :disabled="!branchId"
          @click="nextStep">
        下一步
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  // import { BIconCheckCircleFill } from 'bootstrap-vue';
  import ShopSelection from "@/components/Page/Liff/Branch/ShopSelection";
  import branchMixin from "@/mixins/Dashboard/branches";
  import reservationMixin from "@/mixins/liff/reservation";
  import _ from 'lodash';

  export default {
    mixins: [branchMixin, reservationMixin],
    components: {
      // BIconCheckCircleFill,
      ShopSelection,
    },
    data() {
      return {
        branchId: null,
        selectedBranch: {},
        fetching: false,
        bookingPreset: {},
        reservationBranchProvider: 'waltily.branch',
        isShowBranchCode: false,
        displayPlaceholder: ""
      }
    },
    created() {
      this.init();
    },
    methods: {
      async fetchBranches() {
        try {
          await this.$refs.shopSelection.fetchBranchesOptions();
        } catch (e) {
          if (!this.branchProvider) {
            this.$swal("請設定分店提供者", "", "warning");
          } else {
            console.error(e)
          }
        }
      },
      handleBranchesAreLoading() {
        this.fetching = true;
      },
      handleBranchesAreLoaded() {
        this.fetching = false;
      },
      async init() {
        const data = await this.fetchPresetData(this.$route.params.bookingPresetId);
        this.bookingPreset = data
        const provider = _.get(this.bookingPreset, 'config.branch.field_id', "");
        this.isShowBranchCode = _.get(this.bookingPreset, 'config.show_branch_code', false);
        if (provider) {
          const branchField = _.get(this.bookingPreset, 'config.fields', []).find((field) => { return field._id == provider })
          this.reservationBranchProvider = branchField?.branch_provider
          const text = branchField?.default_select_text
          this.displayPlaceholder = (text == '' || text == null || text == 'null') ? '請選擇區域櫃點' : text
        }
        this.$nextTick(function() {
          this.fetchBranches();
        })
      },
      setSelectedBranch(value) {
        this.selectedBranch = value
      },
      async nextStep() {
        await this.fetchPresetData(this.$route.params.bookingPresetId, this.branchId);
        this.set('branch', {
          id: this.branchId,
          name: this.selectedBranch.name,
          model: {
            branch_provider: this.reservationBranchProvider,
            text: this.selectedBranch.name,
            value: this.selectedBranch.id,
          }
        })
        this.nextStepByList("LiffReservationBranch", {
          branch_id: this.branchId
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
.liff-staff-list {

  &__checkbox {
    display: none;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 0 16px 16px 16px;
    &.need-bottom {
      margin-bottom: 150px;
    }

    &-item {
      display: flex;
      align-items: center;
      border: 1px solid #E5E5EA;
      border-radius: 10px;
      padding: 12px;
      color: #2C2C2E;
      font-size: 18px;
      font-weight: 500;
      gap: 12px;
      cursor: pointer;
      position: relative;
      &:hover {
        background-color: #F5F5F5;
      }
      &.active {
        border: 2px solid var(--s-primary);
      }
      &-img {
        width: 80px;
        height: 80px;
        border-radius: 50px;
        flex-shrink: 0;
      }
      &-info {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 12px;
      }
      &-check {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 32px;
        height: 32px;
        padding: 4px;
      }
    }
  }

  &__card {
		border-radius: 10px;
		border: none;
		box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);

    .card-body {
      padding: 0;
    }
	}

  &__confirm {
    position: fixed;
    max-width: 768px;
    margin: auto;
    background-color: #fff;
    bottom: 0;
    width: 100%;
    left: 0;
    right: 0;
    padding: 12px;
    box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.1);
    font-size: 13px;
  }
}

.spinner-border {
  border-width: 4px;
}

</style>
